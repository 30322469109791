
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
@Component
export default class Maintenance extends Vue {
  private name = 'Maintenance';
  private mounted() {
    store.commit('loaded', true);
  }
}
